// export const baseurl = "http://localhost:8001";
export const baseurl = "https://devapi.redesk.in";

const ApiConfig = {
  signupwithorgs: `${baseurl}/api/signupwithorgs`,
  signinwithorgs: `${baseurl}/api/signinwithorgs`,
  subscribeToNotification: `${baseurl}/api/subscribeToNotification`,
  projectList: `${baseurl}/api/projectList`,
  getOrganisationList: `${baseurl}/api/getOrganisationList`,
  createorganisation: `${baseurl}/api/createOrganisation`,
  sendOTP: `${baseurl}/api/sendOTP`,
  verifyOtp: `${baseurl}/api/verifyOtp`,
  createOrganisationfromEmail: `${baseurl}/api/createOrganisationfromEmail`,
  selectOrganization: `${baseurl}/api/selectOrganization`,
  addproject: `${baseurl}/api/project`,
  verify_organisation: `${baseurl}/api/verifyorganisation`,
  verifyEmailInvite: `${baseurl}/api/verifyEmailInvite`,
  projectdetails: `${baseurl}/api/projectdetails`,
  projectEdit: `${baseurl}/api/projectEdit`,
  projectTaskCount: `${baseurl}/api/projectTaskCount`,
  changeUserRoles: `${baseurl}/api/changeUserRoles`, //role change
  subscribe: `${baseurl}/api/subscribe`,
  taskListByProject: `${baseurl}/api/taskListByProject`,
  changeTaskStatus: `${baseurl}/api/changeTaskStatus`,
  getdashboarddetails: `${baseurl}/api/getdashboarddetails`,
  userList: `${baseurl}/api/userList`,
  taskList: `${baseurl}/api/taskList`,
  Task: `${baseurl}/api/Task`,
  forgetPassword: `${baseurl}/api/forgetPassword`,
  changePassword: `${baseurl}/api/changePassword`,
  taskdetailsbyID: `${baseurl}/api/task/`,
  sendInviteFromCSV: `${baseurl}/api/sendInviteFromCSV`,
  taskcount: `${baseurl}/api/taskcount`,
  projectMembers: `${baseurl}/api/projectMembers`,
  userApproveOrReject: `${baseurl}/api/userApproveOrReject`,
  sendInvite: `${baseurl}/api/sendInvite`,
  getLogs: `${baseurl}/api/getLogs`,
  teamleader: `${baseurl}/api/teamleader`,
  observerlist: `${baseurl}/api/observerlist`,
  employeelist: `${baseurl}/api/employeelist`,
  clientList: `${baseurl}/api/clientList`,
  user: `${baseurl}/api/allUserFromOrgs`,
  taskArray: `${baseurl}/api/taskArray`,
  createclient: `${baseurl}/api/createclient`,
  editClient: `${baseurl}/api/editClient/`,
  addTaskComment: `${baseurl}/api/addTaskComment`,
  createobserver: `${baseurl}/api/createobserver`,
  editObserver: `${baseurl}/api/editObserver/`,
  createsubadmin: `${baseurl}/api/createsubadmin`,
  subadminList: `${baseurl}/api/subadminList`,
  editsubadmin: `${baseurl}/api/editsubadmin/`,
  addProjectAttachment: `${baseurl}/api/addProjectAttachment/`,
  projectEfficiency: `${baseurl}/api/projectEfficiency/`,
  loggedInUser: `${baseurl}/api/loggedInUser`,
  createCategory: `${baseurl}/api/createCategory`,
  categorylist: `${baseurl}/api/categorylist`,
  fileList: `${baseurl}/api/fileList`,
  folderList: `${baseurl}/api/folderList `,
  upload: `${baseurl}/api/upload`,
  taskDelete: `${baseurl}/api/task/`,
  projectAttachmentDelete: `${baseurl}/api/deleteprojectAttachment/`,
  changeProjectStatus: `${baseurl}/api/changeProjectStatus/`,
  changeLoginStatus: `${baseurl}/api/changeLoginStatus`,
  createGroup: `${baseurl}/api/createGroup`,

  // Chat
  chatList: `${baseurl}/api/chatList`, //done
  getChat: `${baseurl}/api/getChat`,
  getChatMessage: `${baseurl}/api/getChatMessage`,
  sendMessage: `${baseurl}/api/sendMessage`,
  searchUser: `${baseurl}/api/user`,
  groupChat: `${baseurl}/api/group`,
  renameGroup: `${baseurl}/api/renameGroup`,
  createChat: `${baseurl}/api/createChat`,
  removeGroupMember: `${baseurl}/api/removeGroupMember`,
  addGroupMember: `${baseurl}/api/addGroupMember`,
  getUserListForChat: `${baseurl}/api/getUserListForChat`,
};

export default ApiConfig;
