import React from "react";

const PageLoading = () => {
  const HandleUseThis = (ev) => {
    ev.target.src = "./images/redesk.png";
  };

  return (
    <div className="container h-100">
      <div className="loading-image-box">
        <div>
          <img
            src="../images/redesk.png"
            alt="Page Logo"
            className="img-fluid"
            onError={HandleUseThis}
          />
          {/* <img
          src="./images/page-loading.gif"
          alt="Page Logo"
          className="img-fluid"
        /> */}
          {/* <img
            src="./images/page-loading1.gif"
            alt="Page Logo"
            className="img-fluid"
          /> */}
          {/* <img
          src="./images/page-loading2.gif"
          alt="Page Logo"
          className="img-fluid"
        /> */}
        </div>
      </div>
    </div>
  );
};

export default PageLoading;
