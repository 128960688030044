import { useState, useEffect, useContext } from "react";
import DataContext from "../Context/DataContext/Context";
import { Navigate } from "react-router-dom";

const Auth = ({ children }) => {
  const ContextData = useContext(DataContext);
  const [authCheck, setAuthCheck] = useState(async () => {
    try {
      const token = await ContextData?.token;

      if (Boolean(token)) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
     
    }
  });
  // const [authCheck, setAuthCheck] = useState(async () => {
  //   const token = await ContextData?.token;
  //   if (token) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // });

  const IncorrectAuth = () => {
    return <Navigate to="/login" />;
  };
  return <>{authCheck ? children : <IncorrectAuth />}</>;
};

export default Auth;

// import { useState, useEffect, useContext } from "react";
// import DataContext from "../Context/DataContext/Context";
// import { Navigate } from "react-router-dom";
// import jwt_decode from "jwt-decode";

// const Auth = (props) => {
//   const ContextData = useContext(DataContext);
//   const [authCheck, setAuthCheck] = useState(false);
//   let tokenexp = false;

//   // useEffect(() => {
//   //   const token = ContextData?.token;
//   //   if (token) {
//   //     let decoded = jwt_decode(token);

//   //     if (decoded?.exp > Date.now() / 1000) {
//   //       console.log("true");
//   //       tokenexp = true;
//   //       setAuthCheck(true);
//   //     } else {

//   //       tokenexp = false;
//   //       setAuthCheck(false);
//   //     }
//   //   }
//   // }, []);

//   const IncorrectAuth = () => {
//     console.log("InCorrect Auth");
//     return <Navigate to="/" />;
//   };
//   const CorrectAuth = (props) => {
//     console.log("Correct Auth");
//     return props?.children;
//   };
//   return <>{tokenexp ? <CorrectAuth props={props} /> : <IncorrectAuth />}</>;
//   // return <>{authCheck ? props?.children : <IncorrectAuth />}</>;
// };

// export default Auth;

// // if (ContextData?.token) {
// //   const token = ContextData?.token;
// //   if (token) {
// //     let decoded = jwt_decode(token);
// //     if (decoded?.exp < Date.now() / 1000) {
// //       setAuthCheck(true);
// //     } else {
// //       setAuthCheck(false);
// //     }
// //   } else {
// //     setAuthCheck(false);
// //   }
// // }

// // const checkTokenValidity = () => {
// //   const authToken = localStorage.getItem('authToken');

// //   if (!authToken) {
// //     // Token not found
// //     return false;
// //   }

// //   // Decode the token
// //   let decodedToken;
// //   try {
// //     decodedToken = jwt_decode(authToken); // Assuming you are using a JWT token
// //   } catch (error) {
// //     // Invalid token format
// //     return false;
// //   }

// //   // Check the token expiration
// //   const currentTime = Date.now() / 1000; // Convert to seconds
// //   if (decodedToken.exp && decodedToken.exp < currentTime) {
// //     // Token has expired
// //     return false;
// //   }

// //   // Additional validation checks, if needed
// //   // ...

// //   return true;
// // };

// // import React, { useEffect } from 'react';
// // import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

// // const checkTokenValidity = () => {
// //   // Retrieve the authentication token from local storage
// //   const authToken = localStorage.getItem('authToken');

// //   // Check if the token is invalid or expired
// //   const isTokenValid = // Perform your token validation logic here

// //   return isTokenValid;
// // };

// // const LogoutButton = () => {
// //   const handleLogout = () => {
// //     // Clear the authentication token from local storage
// //     localStorage.removeItem('authToken');

// //     // Redirect to the login page
// //     window.location.href = '/login'; // Use window.location.href for full page refresh
// //   };

// //   return (
// //     <button onClick={handleLogout}>
// //       Logout
// //     </button>
// //   );
// // };

// // const HomePage = () => {
// //   return (
// //     <div>
// //       <h1>Welcome to the Home Page</h1>
// //       <LogoutButton />
// //     </div>
// //   );
// // };

// // const LoginPage = () => {
// //   return (
// //     <div>
// //       <h1>Login Page</h1>
// //       {/* Login form goes here */}
// //     </div>
// //   );
// // };

// // const PrivateRoute = ({ component: Component, ...rest }) => {
// //   const isTokenValid = checkTokenValidity();

// //   return (
// //     <Route
// //       {...rest}
// //       render={props =>
// //         isTokenValid ? (
// //           <Component {...props} />
// //         ) : (
// //           <Redirect to="/login" />
// //         )
// //       }
// //     />
// //   );
// // };

// // const App = () => {
// //   useEffect(() => {
// //     const isTokenValid = checkTokenValidity();

// //     if (!isTokenValid) {
// //       // Token is invalid or expired, clear it from local storage
// //       localStorage.removeItem('authToken');
// //     }
// //   }, []);

// //   return (
// //     <Router>
// //       <Switch>
// //         <Route exact path="/login" component={LoginPage} />
// //         <PrivateRoute exact path="/" component={HomePage} />
// //       </Switch>
// //     </Router>
// //   );
// // };

// // export default App;
