import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDbSxpODUnjlIq5JvYgpphI9MhRR-k2DeM",
  authDomain: "web-app-67650.firebaseapp.com",
  projectId: "web-app-67650",
  storageBucket: "web-app-67650.appspot.com",
  messagingSenderId: "777163183197",
  appId: "1:777163183197:web:85e677f0a43e5e0e4d29b4",
  measurementId: "G-HX8GKVDNTR",
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

export const get_Token = async (setTokenFound) => {
  let currentToken = "";
  try {
    currentToken = await getToken(messaging, { vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
      return currentToken;
    } else {
      setTokenFound(false);
      return currentToken;
    }
  } catch (error) {
   
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
