import { lazy } from "react";

import LoginLayout from "./Layout/Login";
import HomeLayout from "./Layout/Home";
import { useContext } from "react";
import DataContext from "./Context/DataContext/Context";
import DashboardLayout from "./Layout/Dashboard";

// const ContextData = useContext(DataContext);

export const Routing = [
  {
    path: "/",
    guard: false,
    layout: HomeLayout,
    element: lazy(() => import("./View/LandingPages/index")),
  },

  {
    path: "/login",
    guard: false,
    layout: LoginLayout,
    element: lazy(() => import("./View/Authentication/Login")),
  },
  {
    path: "/signup",
    guard: false,
    layout: LoginLayout,
    element: lazy(() => import("./View/Authentication/Signup")),
  },
  // {
  //   path: "/forgetpassword",
  //   guard: false,
  //   layout: LoginLayout,
  //   element: lazy(() => import("./View/Authentication/ForgetPassword")),
  // },
  // {
  //   path: "/otp",
  //   guard: false,
  //   layout: LoginLayout,
  //   element: lazy(() => import("./View/Authentication/OTP")),
  // },
  // {
  //   path: "/newpassword",
  //   guard: true,
  //   layout: DashboardLayout,
  //   // layout: LoginLayout,
  //   element: lazy(() => import("./View/Authentication/CreateNewPassword")),
  // },
  {
    path: "/organizations",
    guard: false,
    layout: LoginLayout,
    element: lazy(() => import("./View/Authentication/SelectOrganization")),
  },
  // {
  //   path: "/verified",
  //   guard: false,
  //   layout: HomeLayout,
  //   element: lazy(() => import("./View/Others/Verified")),
  // },
  {
    path: "/new_register",
    guard: false,
    layout: LoginLayout,
    element: lazy(() => import("./View/Authentication/AddOrganisation")),
  },
  {
    path: "/registerOrgs",
    guard: false,
    layout: LoginLayout,
    element: lazy(() => import("./View/Authentication/OrganiationName")),
  },
  {
    path: "/timer",
    guard: false,
    // layout: LoginLayout,
    element: lazy(() => import("./Components/Timer")),
  },
  {
    path: "/home",
    guard: false,
    layout: HomeLayout,
    element: lazy(() => import("./View/LandingPages/index")),
  },
  // {
  //   path: "/select_orgs",
  //   element: lazy(() => import("./View/Others/OrganizationSelect")),
  // },
  {
    path: "/dashboard",
    guard: true,
    layout: DashboardLayout,
    element: lazy(() => import("./View/Dashboard/Dashboard")),
  },
  {
    path: "/task",
    guard: true,
    layout: DashboardLayout,
    element: lazy(() => import("./View/Dashboard/Task")),
  },
  {
    path: "/message",
    guard: true,
    layout: DashboardLayout,
    element: lazy(() => import("./View/Dashboard/Message")),
  },
  {
    path: "/taskpage",
    guard: true,
    layout: DashboardLayout,
    element: lazy(() => import("./View/Dashboard/Task/TaskPage")),
  },
  {
    path: "/profile",
    guard: false,
    layout: DashboardLayout,
    element: lazy(() => import("./View/Dashboard/Profile/Profile")),
  },
  {
    path: "/project",
    guard: true,
    layout: DashboardLayout,
    element: lazy(() => import("./View/Dashboard/Project")),
  },
  {
    path: "/file_manager",
    guard: true,
    layout: DashboardLayout,
    element: lazy(() => import("./View/Dashboard/File manager")),
  },
  {
    path: "/userlist",
    guard: false,
    layout: DashboardLayout,
    element: lazy(() => import("./View/Dashboard/Users")),
  },
  {
    path: "/reports",
    guard: false,
    layout: DashboardLayout,
    element: lazy(() => import("./View/Dashboard/Reports")),
  },
  {
    path: "/drag",
    // guard: false,
    layout: DashboardLayout,
    element: lazy(() => import("./View/Dashboard/Task/TaskDrag/DragDrop")),
  },
  {
    path: "/chat",
    guard: false,
    layout: DashboardLayout,
    element: lazy(() => import("./View/Dashboard/Chat/index")),
  },
  {
    path: "/settings",
    guard: false,
    layout: DashboardLayout,
    element: lazy(() => import("./View/Dashboard/Settings/index")),
  },
  {
    path: "/projectdetails",
    guard: true,
    layout: DashboardLayout,
    element: lazy(() => import("./View/Dashboard/Project/ProjectPage")),
  },
  {
    path: "/loadingpage",
    guard: false,
    element: lazy(() => import("./Components/PageLoading")),
  },
  {
    path: "*",
    guard: false,
    element: lazy(() => import("./View/Error/NotFound")), // 404 Page
  },
];
