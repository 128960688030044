import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LoginLayout = ({ children }) => {
  const navigate = useNavigate();
  const [authTab, setAuthTab] = useState();

  useEffect(() => {
    setAuthTab(window.location.pathname.slice(1));
  });

  return (
    <>
      <div className="auth-layout-back container-fluid">
        <div className="row h-100">
          <div className="col-md-7 col-lg-6 d-flex ">
            <div className="form-container-box container-fluid">
              <div className="row">
                <div className="col-md-12" style={{ height: "200px" }}>
                  <div className="auth-logo-container">
                    <img
                      src="../images/redesk.png"
                      alt="Logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-md-12 p-0">
                  <div className="form-box ">{children}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginLayout;
// {children}
