import { QuestionAnswer } from "@mui/icons-material";
import { Box, Drawer, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import DataContext from "../../../Context/DataContext/Context";

const ChatModule = ({ token }) => {
  const [open, setOpen] = useState(false);

  const [loading, stillLoading] = useState(true);
  const ContextData = useContext(DataContext);
  const current_token = ContextData?.token;
  const finishLoading = () => {
    stillLoading(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    finishLoading();
  }, []);
  return (
    <>
      <Tooltip title="Chat">
        <IconButton size="large" onClick={handleOpen}>
          <QuestionAnswer />
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        onClose={handleClose}
        open={open}
        className="modal-blur"
      >
        {/* &sub_domain=${} */}
        <Box className="sidersheet-drawer-400">
          <div className="chat-comp">
            {loading ? (
              <div class="spinner-border" role="status"></div>
            ) : (
              <iframe
                src={`http://localhost:5173/?token=${
                  ContextData?.token?.split(" ")[1]
                }`}
                // src={`http://rechat.redesk.in/?token=${
                //   ContextData?.token?.split(" ")[1]
                // }`}
                height="100%"
                width="100%"
                title="Chat Application"
                scrolling="no"
                style={{ border: "2px solid #fad3d3" }}
                onLoad={finishLoading}
              ></iframe>
            )}
          </div>
        </Box>
      </Drawer>
    </>
  );
};

export default ChatModule;
