import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import DataContext from "../../Context/DataContext/Context";
import { useState, useEffect, useContext } from "react";
import {
  Dashboard,
  ContentPasteGo,
  Task,
  Group,
  Settings,
  CalendarMonth,
  Inventory2,
  Newspaper,
  Chat,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import InnerLogo from "../../Components/InnerLogo";

const menuItems = [
  {
    item: "Dashboard",
    href: "/dashboard",
    related_routes: ["/dashboard"],
    icon: Dashboard,
    permission: [
      "admin",
      "subadmin",
      "team_leader",
      "user",
      "client",
      "observer",
    ],
  },
  {
    permission: [
      "admin",
      "subadmin",
      "team_leader",
      "user",
      "client",
      "observer",
    ],
    item: "Project",
    href: "/project",
    related_routes: ["/project", "/projectdetails"],
    icon: ContentPasteGo,
  },
  {
    permission: [
      "admin",
      "subadmin",
      "team_leader",
      "user",
      "client",
      "observer",
    ],
    item: "Task",
    related_routes: ["/task", "/taskpage"],
    href: "/task",
    icon: Task,
  },
  // {
  //   permission: [
  //     "admin",
  //     "subadmin",
  //     "team_leader",
  //     "user",
  //     "client",
  //     "observer",
  //   ],
  //   item: "File",
  //   related_routes: ["/file_manager"],
  //   href: "/file_manager",
  //   icon: Inventory2,
  // },
  {
    permission: ["admin", "team_leader", "user", "client", "observer"],
    item: "Message",
    href: "/message",
    icon: Chat,
  },
  {
    permission: ["admin", "subadmin", "team_leader", "observer"],
    item: "Reports",
    href: "/reports",
    related_routes: ["/reports"],
    icon: Newspaper,
  },
  {
    permission: ["admin", "subadmin", "team_leader", "observer"],
    item: "Users",
    href: "/userlist",
    related_routes: ["/userlist"],
    icon: Group,
  },
  {
    permission: ["admin", "subadmin", "team_leader", "observer"],
    item: "Settings",
    href: "/settings",
    icon: Settings,
    related_routes: ["/settings"],
  },
];

const NavItem = ({ icon: Icon, label, href, permission, related_routes }) => {
  const navigate = useNavigate();
  const ContextData = useContext(DataContext);
  const [active, setActive] = useState(false);
  useEffect(() => {
    if (window.location.pathname === `${href}`) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [navigate]);

  return (
    <>
      <ListItemButton
        className={
          active ? "sidebar-active sidebar-listitem" : "sidebar-listitem"
        }
        onClick={() => {
          navigate(href);
        }}
      >
        <Icon style={{ fontSize: "30px" }} /> &nbsp;&nbsp;
        <ListItemText className="font-family-sidebar">{label}</ListItemText>
      </ListItemButton>
    </>
  );
};
export const drawer = (
  <>
    <div className="px-3 my-3">
      <InnerLogo />
    </div>
    <Divider />
    <List>
      {menuItems.map((text, index) => {
        return (
          <>
            <ListItem key={index} disablePadding>
              <NavItem
                icon={text?.icon}
                label={text?.item}
                href={text?.href}
                permission={text?.permission}
                related_routes={text?.related_routes}
              />
            </ListItem>
          </>
        );
      })}
    </List>
    <Divider />
  </>
);
