import { ArrowDropDown } from "@mui/icons-material";
import { Avatar, Box, Fade, Popper } from "@mui/material";
import React, { useRef, useState } from "react";

const style = {
  border: 1,
  padding: "3px 8px",
  bgcolor: "background.paper",
  borderRadius: "5px",
};

const UserProfilePic = (props) => {
  const data = props?.data;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const arrowRef = useRef(null);
  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "spring-popper" : undefined;
  const handleActive = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleInative = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const HandleUseThis = (ev) => {
    ev.target.src = "./images/user default image.png";
  };
  return (
    <>
      <div
        // onClick={handleActive}
        onMouseEnter={handleActive}
        onMouseLeave={handleInative}
        className="user-profile-image-icon"
      >
        <Avatar
          alt={data?.name}
          sx={{ width: 35, height: 35 }}
          src={data?.pic}
          onError={HandleUseThis}
        />
      </div>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        sx={{ zIndex: 1 }}
        placement="bottom"
        disablePortal={true}
        modifiers={[
          {
            name: ArrowDropDown,
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Box sx={style}>
              <div className="profile_name">{data?.name}</div>
            </Box>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default UserProfilePic;
