import React from "react";

const Footer = () => {
  return (
    <div
      class="text-center p-4"
      style={{ backgroundColor: " rgba(0, 0, 0, 0.025)" }}
    >
      Powered By: &nbsp;&nbsp;
      <a class=" fw-bold main-color-text" href="https://apptimates.com/">
        Apptimate
      </a>
    </div>
  );
};

export default Footer;
