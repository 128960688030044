import React, { useState, useEffect } from "react";
import { get_Token } from "../firebase";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";

const Notifications = (props) => {
  const [isTokenFound, setTokenFound] = useState(false);

 

  // To load once
  useEffect(() => {
    let data;

    async function tokenFunc() {
      try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          data = await get_Token(setTokenFound);
          if (data) {
            secureLocalStorage.setItem("firebase_token", data);
            
          }
          return data;
        } else if (permission === "denied") {
          
          // toast.info("Please enable notification permission");
        }
      } catch (error) {
      
      }
    }

    tokenFunc();
  }, []);

  return <></>;
};

Notifications.propTypes = {};

export default Notifications;
