import addNotification from "react-push-notification";

const NotificationComponent = ({ title, body }) => {
  const buttonClick = () => {
    addNotification({
      title: title,
      //   subtitle: "This is a subtitle",
      message: body,
      duration: 5000,
      theme: "darkblue",  
      native: true, // when using native, your OS will handle theming.
    });
  };

  return buttonClick();
};

export default NotificationComponent;
