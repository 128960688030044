import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Box } from "@mui/material";

const HomeLayout = ({ children }) => {
  return (
    <>
      <Box
        className="home-landing-layout"
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Header />
        <Box
          className="set-height"
          component="main"
          sx={{ p: { xs: 2, md: 3 } }}
        >
          {children}
        </Box>
        {!window.location.pathname === "verified" && (
          <Box className="footer-comp" component="footer">
            <Footer />
          </Box>
        )}
      </Box>
    </>
  );
};

export default HomeLayout;
