export const user_role = {
  admin: "Admin",
  user: "User",
  team_leader: "Team Leader",
  subadmin: "Sub Admin",
  observer: "Observer",
  client: "Client",
};

export const user_status = {
  pending: "Pending",
  approved: "Approved",
  rejected: "Rejected",
};

const loginStatus = {
  0: "OFFLINE",
  1: "ONLINE",
};

export const project_status = [
  {
    id: 1,
    key: "ACTIVE",
    label: "Active",
  },
  {
    id: 2,
    key: "HOLD",
    label: "Hold",
  },
  {
    id: 3,
    key: "COMPLETED",
    label: "Completed",
  },
  {
    id: 4,
    key: "INACTIVE",
    label: "Inactive",
  },
];

export const project_status_obj = {
  ACTIVE: 1,
  HOLD: 2,
  COMPLETED: 3,
  INACTIVE: 4,
};

export const user_status_id = {
  pending: 2,
  approved: 1,
  rejected: 0,
};

export const priorityObj = {
  HIGH: "High",
  MEDIUM: "Medium",
  LOW: "Low",
};

export const taskListCount = {
  ACTIVE: "Active",
  COMPLETED: "Completed",
  HOLD: "Hold",
};

export const taskId = {
  1: "Pending",
  2: "In Process",
  3: "Testing",
  4: "Completed",
  5: "Backlogs",
};

export const taskBYtab = {
  Active: "Pending",
  InProcess: "In Process",
  Testing: "Testing",
  COMPLETED: "Completed",
  Backlogs: "Backlogs",
  Confirmed: "Backlogs",
};
export const taskbystatus = {
  1: "Pending",
  2: "InProcess",
  3: "Testing",
  4: "COMPLETED",
  5: "Backlogs",
};
export const project_category_obj = {
  mobile_application: "Mobile Application",
  web_application: "Web Application",
};

// {
//   id: 1,
//   key: "mobile_application",
//   label: "Mobile Application",
// },
// {
//   id: 2,
//   key: "web_application",
//   label: "Web Application",
// },

export const user_role_updated = {
  1: "admin",
  2: "subadmin",
  3: "team_leader",
  4: "observer",
  5: "client",
  6: "user",
};
