import React, { useEffect, useState, useRef } from "react";
import DataContext from "./Context";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import ApiConfig, { baseurl } from "../../Config/ApiConfig";

import { io } from "socket.io-client";
const ContextState = (props) => {
  const [user, setUser] = useState(
    secureLocalStorage.getItem("redesk-user")
      ? secureLocalStorage.getItem("redesk-user")
      : ""
  );
  const [token, setToken] = useState(
    secureLocalStorage.getItem("redesk-token")
      ? secureLocalStorage.getItem("redesk-token")
      : ""
  );
  const [firebaseToekn, setFirebaseToekn] = useState(
    secureLocalStorage.getItem("firebase_token")
  );

  useEffect(() => {
    setFirebaseToekn(secureLocalStorage.getItem("firebase_token"));
  }, [token]);

  const [selectChatData, setSelectedChatData] = useState();
  const [notificationtab, setNotificationTab] = useState(false);
  const [organizationKey, setOrganizationKey] = useState(
    secureLocalStorage.getItem("redesk-company")
  );

  const [organizationList, setOrganizationList] = useState();
  const [taskAdd, setTaskAdd] = useState(0);

  const UserObject = {};
  const OrganizationObject = {};
  const [userList, setUserList] = useState();

  const handleUserList = async () => {
    try {
      const res = await axios.get(ApiConfig.user, {
        headers: { Authorization: token },
      });
      if (res.status === 200 || res.data.status === "200") {
        setUserList(res?.data?.docs);
      }
    } catch (error) {
      // toast.error(error?.response?.data?.message);
    }
  };

  if (userList) {
    for (let i = 0; i < userList.length; i++) {
      UserObject[userList[i]?._id] = userList[i]?.name;
    }
  }

  useEffect(() => {
    if (token) {
      handleUserList();
    }
  }, []);

  if (organizationList) {
    for (let i = 0; i < organizationList.length; i++) {
      OrganizationObject[organizationList[i]?.sub_domain] =
        organizationList[i]?.organisation_name;
    }
  }

  const SessionLogout = async (error) => {
    const response_code = await error?.response?.data?.status;
    const response_message = await error?.response?.data?.message;
    if (
      response_code === 401 ||
      response_message === "Unauthorized!TokenExpiredError: jwt expired"
    ) {
      secureLocalStorage.removeItem("redesk-user");
      secureLocalStorage.removeItem("redesk-token");
      secureLocalStorage.removeItem("redesk-organization");
      setToken();
      setUser();
    }
  };

  const handleLogout = async () => {
    secureLocalStorage.removeItem("redesk-user");
    secureLocalStorage.removeItem("redesk-token");
    secureLocalStorage.removeItem("redesk-organization");
    setToken();
    setUser();
  };

  // firebaseToken config

  const sendFirebasetoken = async (auth_token) => {
    try {
      const res = await axios.post(
        ApiConfig.subscribeToNotification,
        {
          registrationToken: firebaseToekn,
        },
        { headers: { Authorization: auth_token } }
      );
      if (res.status === 200 || res.status === "200") {
      }
    } catch (error) {}
  };

  // Check Online / offline

  const online_offline = async (gettoken, value) => {
    try {
      const res = await axios.post(
        ApiConfig.changeLoginStatus,
        { isOnline: value },
        {
          headers: {
            Authorization: gettoken,
          },
        }
      );
    } catch (error) {}
  };

  // Chat Socket
  const [selectedChat, setSelectedChat] = useState("");

  const [chatCount, setChatCount] = useState();

  const Client = useRef(null);
  const [chatCounter, setChatCounter] = useState(0);
  const [clientSocket, setClientSocket] = useState(io(baseurl));
  useEffect(() => {
    // setClientSocket();
    clientSocket.emit("userJoin", { id: user?._id });
    Client.current = clientSocket;
  }, [token]);

  useEffect(() => {
    setFirebaseToekn(secureLocalStorage.getItem("firebase_token"));
  }, [token]);

  return (
    <DataContext.Provider
      value={{
        token,
        setToken,
        OrganizationObject,
        organizationList,
        setOrganizationList,
        user,
        setUser,
        organizationKey,
        taskAdd,
        setTaskAdd,
        setOrganizationKey,
        UserObject,
        userList,
        setUserList,
        SessionLogout,
        handleLogout,
        notificationtab,
        setFirebaseToekn,
        setNotificationTab,
        sendFirebasetoken,
        selectedChat,
        setSelectedChat,
        chatCounter,
        setChatCounter,
        Client,
        clientSocket,
        setClientSocket,
        chatCount,
        setChatCount,
        online_offline,
        selectChatData,
        setSelectedChatData,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

export default ContextState;

// selectChatData, setSelectedChatData
