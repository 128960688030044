import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Suspense, Fragment, useEffect, useState, useContext } from "react";
import DataContext from "./Context/DataContext/Context";
import React from "react";
import "./SASS/main.css";
import { Routing } from "./Routes";
import PageLoading from "./Components/PageLoading";
import Auth from "./Config/Auth";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { onMessageListener } from "./firebase";
import Notifications from "./Components/Notification";
import NotificationComponent from "./Components/NotificationComponent";

// .

function App() {
  const ContextData = useContext(DataContext);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });

  console.log("adsadasd", show, notification);

  onMessageListener()
    .then((payload) => {
      setShow(true);

      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      console.log("dasdas", payload);
      // return window.self.registration.showNotification({
      //   title: payload.notification.title,
      //   body: payload.notification.body,
      // });
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <>
      <ToastContainer />
      <Notifications />
        {show && (
          <NotificationComponent
            title={notification.title}
            body={notification.body}
          />
        )}

      <BrowserRouter>
        <RenderRoutes token={ContextData?.token} data={ContextData} />
      </BrowserRouter>
    </>
  );
}

export default App;

// "Montserrat"

const RenderRoutes = ({ token, data }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [user, setLoginUser] = useState(false);

  return (
    <Suspense fallback={<PageLoading />}>
      <Routes>
        {Routing &&
          Routing.map((values, i) => {
            const Component = values?.element;
            const Layout = values.layout || Fragment;
            const Guard = values?.guard ? Auth : Fragment;

            return (
              <Fragment key={i}>
                <Route
                  path={values?.path}
                  element={
                    <Guard>
                      <Layout>
                        <Component
                          token={token}
                          // updateUser={updateUser}
                          user={user}
                          {...Routing}
                        />
                      </Layout>
                    </Guard>
                  }
                />
              </Fragment>
            );
          })}
      </Routes>
    </Suspense>
  );
};
